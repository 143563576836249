import { ProgramSetStore } from 'src/app/infrastructure/models/interfaces/program-set-store';
import { ProgramSet } from '../models/program-set';
import { Program } from '../../all-programs/models/program';
import { computed } from '@angular/core';
import { DisplaySetInList } from 'src/app/features/plan-set-program/models/display-set-in-list';
import { SpecificProgramServiceService } from 'src/app/infrastructure/services/specific-program/specific-program-service.service';
import { ProgramSettings } from 'src/app/infrastructure/models/program-settings';
import { PlanSpecificSetProgram } from 'src/app/features/plan-set-program/models/interfaces/plan-specific-set-program';
import { ProgramStore } from 'src/app/infrastructure/models/interfaces/program-store';

export abstract class PlanSpecificSetProgramService<
    TSet extends ProgramSet,
    TProgram extends Program
  >
  extends SpecificProgramServiceService
  implements PlanSpecificSetProgram
{
  private currentProgramId = computed(
    () => this.programStore.currentProgram().id
  );

  public abstract setNames: string;
  public abstract addSetUrl: string;
  public abstract editSetUrl: string;
  public abstract canCopy: boolean;
  public readonly allowsEdit: boolean = true;

  public currentSets = computed(() => {
    return this.store.currentSetsForProgram(this.currentProgramId())();
  });

  public plannedSets = computed(() => {
    return this.store.plannedSetsForProgram(this.currentProgramId())();
  });

  public completedSets = computed(() => {
    return this.store.completedSetsForProgram(this.currentProgramId())();
  });

  public currentProgram = this.programStore.currentProgram;

  public isLoading = computed(() => {
    return this.store.isLoadingForProgram(this.currentProgramId())();
  });

  public isLoadingMorePlanned = computed(() => {
    const isLoading = this.store.isLoadingMorePlannedForProgram(
      this.currentProgramId()
    )();

    return isLoading;
  });

  public haveLoaded = computed(() => {
    return this.store.haveLoadedForProgram(this.currentProgramId())();
  });

  public haveError = computed(() => {
    return this.store.haveErrorForProgram(this.currentProgramId())();
  });

  public haveErrorForLoadingMorePlanned = computed(() => {
    return this.store.haveErrorForLoadingMorePlanned(this.currentProgramId())();
  });

  constructor(
    protected store: ProgramSetStore<TSet>,
    protected programStore: ProgramStore<TProgram>,
    settings: ProgramSettings[]
  ) {
    super(settings);
  }

  public additionalAddActions:
    | {
        actionName: string;
        navigateTo: string[];
        featureFlag: string | undefined;
      }[]
    | undefined = undefined;

  public loadFrontSets(): void {
    this.store.loadFrontSets(this.currentProgramId());
  }

  public getSetById(setId: string): TSet {
    const set = this.store.entities().find((x) => x.id === setId);
    return set;
  }

  public loadMorePlanned(): void {
    this.store.loadMorePlanned(this.currentProgramId());
  }

  public moveToCurrent(setId: string): void {
    this.store.moveToCurrent({ setId, programId: this.currentProgramId() });
  }

  public moveToCompleted(setId: string): void {
    this.store.moveToCompleted({ setId, programId: this.currentProgramId() });
  }

  public moveToPlanned(setId: string): void {
    this.store.moveToPlanned({ setId, programId: this.currentProgramId() });
  }

  public removeSet(setId: string): void {
    this.store.removeEntity(this.getSetById(setId));
  }

  public changePosition(setId: string, position: number): void {
    this.store.changePosition({
      setId,
      programId: this.currentProgramId(),
      position,
    });
  }

  public getSetToDisplay(setId: string): DisplaySetInList {
    const set = this.getSetById(setId);
    return this.getSetAsDisplay(set);
  }

  public abstract getSetAsDisplay(set: TSet): DisplaySetInList;

  public abstract getNbrOfContentOnPrintedPage(): number;
  public abstract getContentToPrint(setId: string): string[];
}
